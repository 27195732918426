/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Event action types that can be tracked for payment
 */
export enum ActionType {
  CLICK = "CLICK",
  DEPOSIT = "DEPOSIT",
  INSTALL = "INSTALL",
  REGISTRATION = "REGISTRATION",
}

/**
 * Indicates how transactions should be grouped for calculating tiers
 */
export enum AgreementTierType {
  CLICK = "CLICK",
  SUBAGREEMENT = "SUBAGREEMENT",
}

/**
 * Statically defined error types
 */
export enum ClickServiceErrorType {
  CSV_ERROR = "CSV_ERROR",
  MONTH_CLOSED = "MONTH_CLOSED",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  NOT_FOUND = "NOT_FOUND",
}

/**
 * Event type payments are calculated by
 */
export enum CommissionType {
  CLAWBACK = "CLAWBACK",
  CPA = "CPA",
  HYBRID = "HYBRID",
  REVSHARE = "REVSHARE",
  SPEND = "SPEND",
}

/**
 * Currency type payments will be made in
 */
export enum Currency {
  EUR = "EUR",
  GBP = "GBP",
  USD = "USD",
}

/**
 * Two letter language code
 */
export enum LanguageCode {
  AA = "AA",
  AB = "AB",
  AE = "AE",
  AF = "AF",
  AK = "AK",
  AM = "AM",
  AN = "AN",
  AR = "AR",
  AS = "AS",
  AV = "AV",
  AY = "AY",
  AZ = "AZ",
  BA = "BA",
  BE = "BE",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BR = "BR",
  BS = "BS",
  CA = "CA",
  CE = "CE",
  CH = "CH",
  CO = "CO",
  CR = "CR",
  CS = "CS",
  CU = "CU",
  CV = "CV",
  CY = "CY",
  DA = "DA",
  DE = "DE",
  DV = "DV",
  DZ = "DZ",
  EE = "EE",
  EL = "EL",
  EN = "EN",
  ES = "ES",
  ET = "ET",
  EU = "EU",
  FA = "FA",
  FF = "FF",
  FI = "FI",
  FJ = "FJ",
  FO = "FO",
  FR = "FR",
  FY = "FY",
  GA = "GA",
  GD = "GD",
  GL = "GL",
  GN = "GN",
  GU = "GU",
  GV = "GV",
  HA = "HA",
  HE = "HE",
  HI = "HI",
  HO = "HO",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  HY = "HY",
  HZ = "HZ",
  ID = "ID",
  IG = "IG",
  II = "II",
  IK = "IK",
  IS = "IS",
  IT = "IT",
  IU = "IU",
  JA = "JA",
  JV = "JV",
  KA = "KA",
  KG = "KG",
  KI = "KI",
  KJ = "KJ",
  KK = "KK",
  KL = "KL",
  KM = "KM",
  KN = "KN",
  KO = "KO",
  KR = "KR",
  KS = "KS",
  KU = "KU",
  KV = "KV",
  KW = "KW",
  KY = "KY",
  LA = "LA",
  LB = "LB",
  LG = "LG",
  LI = "LI",
  LN = "LN",
  LO = "LO",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  MG = "MG",
  MH = "MH",
  MI = "MI",
  MK = "MK",
  ML = "ML",
  MN = "MN",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MY = "MY",
  NA = "NA",
  NB = "NB",
  ND = "ND",
  NE = "NE",
  NG = "NG",
  NL = "NL",
  NN = "NN",
  NO = "NO",
  NR = "NR",
  NV = "NV",
  NY = "NY",
  OC = "OC",
  OJ = "OJ",
  OM = "OM",
  OR = "OR",
  OS = "OS",
  PA = "PA",
  PI = "PI",
  PL = "PL",
  PS = "PS",
  PT = "PT",
  QU = "QU",
  RM = "RM",
  RN = "RN",
  RO = "RO",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SI = "SI",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SQ = "SQ",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SU = "SU",
  SV = "SV",
  SW = "SW",
  TA = "TA",
  TE = "TE",
  TG = "TG",
  TH = "TH",
  TI = "TI",
  TK = "TK",
  TL = "TL",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TS = "TS",
  TT = "TT",
  TW = "TW",
  TY = "TY",
  UG = "UG",
  UK = "UK",
  UR = "UR",
  UZ = "UZ",
  VE = "VE",
  VI = "VI",
  WA = "WA",
  WO = "WO",
  XH = "XH",
  YI = "YI",
  YO = "YO",
  ZA = "ZA",
  ZH = "ZH",
  ZU = "ZU",
}

/**
 * Status of payments for specific month
 */
export enum MonthStatusEnum {
  CLOSED = "CLOSED",
  LOCKED = "LOCKED",
}

/**
 * Status for monthly payments being paid to partners
 */
export enum MonthlyPaymentStatus {
  PAID = "PAID",
  PENDING = "PENDING",
  ROLLOVER = "ROLLOVER",
}

/**
 * Revshare types network revshare payments are calculated by
 */
export enum NetworkRevshareType {
  GROSS_GAMING_REVENUE = "GROSS_GAMING_REVENUE",
  NET_REVENUE = "NET_REVENUE",
}

/**
 * Statically defined error types
 */
export enum OfferServiceErrorType {
  BRAND_INACTIVE = "BRAND_INACTIVE",
  MONTH_CLOSED = "MONTH_CLOSED",
  NOT_FOUND = "NOT_FOUND",
  OFFER_INACTIVE = "OFFER_INACTIVE",
}

/**
 * Indicates whether the tier is pay in (NETWORK) or pay out (PARTNER)
 */
export enum PayType {
  NETWORK = "NETWORK",
  PARTNER = "PARTNER",
}

/**
 * Method of payment for user
 */
export enum PaymentMethod {
  BANKWIRE = "BANKWIRE",
  BTCTRANSFER = "BTCTRANSFER",
  CHECK = "CHECK",
  ECOPAYZ = "ECOPAYZ",
  NETELLER = "NETELLER",
  PAYPAL = "PAYPAL",
  PLAYERACCOUNT = "PLAYERACCOUNT",
  SKRILL = "SKRILL",
}

/**
 * Qualification type to enable payment on clicks
 */
export enum QualificationType {
  DEPOSIT_WINDOW = "DEPOSIT_WINDOW",
  MIN_DEPOSIT = "MIN_DEPOSIT",
  MIN_WAGER_AMOUNT = "MIN_WAGER_AMOUNT",
  MIN_WAGER_COUNT = "MIN_WAGER_COUNT",
  MIN_FTD_AMOUNT = "MIN_FTD_AMOUNT",
}

/**
 * Relative transaction event type to base DEPOSIT_WINDOW qualifications off of
 */
export enum QualificationWindowType {
  CLICK = "CLICK",
  INSTALL = "INSTALL",
  REGISTRATION = "REGISTRATION",
}

/**
 * Type of Looker dashboard
 */
export enum ReportType {
  ADMIN_CAMPAIGN = "ADMIN_CAMPAIGN",
  ADMIN_CLICK_DRILLDOWN = "ADMIN_CLICK_DRILLDOWN",
  ADMIN_COHORT = "ADMIN_COHORT",
  ADMIN_CREATIVE = "ADMIN_CREATIVE",
  ADMIN_DAILY = "ADMIN_DAILY",
  ADMIN_DASHBOARD = "ADMIN_DASHBOARD",
  ADMIN_DETAILED_BREAKDOWN = "ADMIN_DETAILED_BREAKDOWN",
  ADMIN_MONTHLY = "ADMIN_MONTHLY",
  ADMIN_OFFER = "ADMIN_OFFER",
  ADMIN_PARTNER = "ADMIN_PARTNER",
  ADMIN_PLAYER = "ADMIN_PLAYER",
  ADMIN_SUBID = "ADMIN_SUBID",
  ADMIN_SUBID_DROPDOWN = "ADMIN_SUBID_DROPDOWN",
  PARTNER_CLICK_DRILLDOWN = "PARTNER_CLICK_DRILLDOWN",
  PARTNER_CREATIVE = "PARTNER_CREATIVE",
  PARTNER_DAILY = "PARTNER_DAILY",
  PARTNER_DASHBOARD = "PARTNER_DASHBOARD",
  PARTNER_OFFER = "PARTNER_OFFER",
  PARTNER_PLAYER = "PARTNER_PLAYER",
  PARTNER_SUBID = "PARTNER_SUBID",
  PARTNER_SUBID_DROPDOWN = "PARTNER_SUBID_DROPDOWN",
}

/**
 * Auth0 values used to sort in ascending or descending order
 */
export enum SortOrder {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

/**
 * Active status of the subagreement
 */
export enum SubagreementStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
}

/**
 * The action or event the tier will be based on
 */
export enum ThresholdType {
  DEPOSIT_AMOUNT = "DEPOSIT_AMOUNT",
  DEPOSIT_COUNT = "DEPOSIT_COUNT",
  DEPOSIT_WINDOW = "DEPOSIT_WINDOW",
  FIRST_TIME_DEPOSIT = "FIRST_TIME_DEPOSIT",
  NET_REVENUE = "NET_REVENUE",
  QUALIFIED_DEPOSIT = "QUALIFIED_DEPOSIT",
  WAGER_AMOUNT = "WAGER_AMOUNT",
  WAGER_COUNT = "WAGER_COUNT",
  WAGER_WINDOW = "WAGER_WINDOW",
}

/**
 * The type of transaction to start the tier window
 */
export enum TierWindowType {
  CLICK = "CLICK",
  FIRST_TIME_DEPOSIT = "FIRST_TIME_DEPOSIT",
  INSTALL = "INSTALL",
  REGISTRATION = "REGISTRATION",
}

/**
 * Currency type transactions were made in
 */
export enum TransactionCurrency {
  EUR = "EUR",
  GBP = "GBP",
  USD = "USD",
}

/**
 * Type of transaction source
 */
export enum TransactionSource {
  CSV = "CSV",
  DATA_PROVIDER_GIG = "DATA_PROVIDER_GIG",
  FTP = "FTP",
  MANUAL = "MANUAL",
  S2S = "S2S",
}

/**
 * Type of transaction event in a click's lifecycle
 */
export enum TransactionType {
  ADJUSTMENT = "ADJUSTMENT",
  ADMIN_FEE = "ADMIN_FEE",
  BONUS = "BONUS",
  CLICK = "CLICK",
  DEPOSIT = "DEPOSIT",
  EXTRA_COST = "EXTRA_COST",
  GROSS_GAMING_REVENUE = "GROSS_GAMING_REVENUE",
  INSTALL = "INSTALL",
  NET_REVENUE = "NET_REVENUE",
  PROVIDER_NET_REVENUE = "PROVIDER_NET_REVENUE",
  REGISTRATION = "REGISTRATION",
  TAX = "TAX",
  WAGER = "WAGER",
  WINNINGS_PAID = "WINNINGS_PAID",
  WITHDRAWAL = "WITHDRAWAL",
}

/**
 * Available default display currencies
 */
export enum UserCurrency {
  BTC = "BTC",
  EUR = "EUR",
  GBP = "GBP",
  USD = "USD",
}

/**
 * Statically defined error types
 */
export enum UserServiceErrorType {
  INVALID_INPUT = "INVALID_INPUT",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  NOT_FOUND = "NOT_FOUND",
}

/**
 * User fields available to be sorted by
 */
export enum UserSortFields {
  CREATED_AT = "CREATED_AT",
  EMAIL = "EMAIL",
  NAME = "NAME",
}

/**
 * Statically defined verticals types
 */
export enum VerticalType {
  BETTING_EXCHANGE = "BETTING_EXCHANGE",
  BINGO = "BINGO",
  CASINO = "CASINO",
  CRYPTO = "CRYPTO",
  DEFAULT = "DEFAULT",
  ESPORTS = "ESPORTS",
  FANTASY = "FANTASY",
  FOREX = "FOREX",
  HORSE_RACING = "HORSE_RACING",
  LIVE_DEALER = "LIVE_DEALER",
  LOTTERY = "LOTTERY",
  OTHER = "OTHER",
  POKER = "POKER",
  SLOTS = "SLOTS",
  SPORTSBOOK = "SPORTSBOOK",
  SWEEPSTAKES = "SWEEPSTAKES",
  TABLE_GAMES = "TABLE_GAMES",
  VIRTUAL = "VIRTUAL",
  default = "default",
}

/**
 * Event action types that can be tracked for payment
 */
export enum WebhookActionType {
  CLICK = "CLICK",
  DEPOSIT = "DEPOSIT",
  DOWNLOAD = "DOWNLOAD",
  QUALIFIED_DEPOSIT = "QUALIFIED_DEPOSIT",
  REGISTRATION = "REGISTRATION",
}

/**
 * HTTP method to use for webhook requests
 */
export enum WebhookHttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
}

export interface AgreementDetailsInput {
  actionType?: ActionType | null;
  cpaAmount?: number | null;
  commissionType: CommissionType;
  currency: Currency;
  revsharePercentages?: any | null;
  revshareType?: NetworkRevshareType | null;
  tierType?: AgreementTierType | null;
  spendPercentage?: number | null;
  spendAccessToken?: string | null;
}

export interface CreateAccountPoolVariableInput {
  description?: string | null;
  name: string;
}

export interface CreateAccountVariableInput {
  name: string;
  value: string;
}

export interface CreateQualificationInput {
  amount: number;
  qualificationType: QualificationType;
  windowType?: QualificationWindowType | null;
}

export interface CreateTierInput {
  cpa?: number | null;
  payType?: PayType | null;
  revsharePercentages?: any | null;
  threshold: number;
  thresholdType: ThresholdType;
  level: number;
  windowType?: TierWindowType | null;
}

export interface MonthlyPaymentTotalsPartnerIdMonth {
  partnerId: string;
  month: any;
  externalComment?: string | null;
  internalComment?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
